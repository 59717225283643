<template>
  <div>
    <section class="report-content" v-if="showReportContent">
      <h1 class="report-title" id="reportTitle">{{columnTitle}} <span class="time fr">{{createTime|ConverToDate}}</span></h1>
      <article class="report-detail" id="content" v-html="content">
      </article>
    </section>
    <err v-if="showErrPage" :errStatus="errStatus"></err>
  </div>
</template>

<script>
  import err from '@/components/err'
  export default {
    data() {
      return {
        createTime: "",
        content: "",
        columnTitle:"",
        showReportContent:false,
        //错误页面
        showErrPage:false,
        errStatus:""
      }
    },
    watch: {
      //检测路由变化
      '$route'(to, from) {
        this.getReportInfo()
      },
    },
    components:{
      err
    },
    mounted(){
      //字体加载完成后再显示对应的内容
      this.getReportInfo()
      // var _this=this;
      // try {
      //   document.fonts.load("16px hzgb")
      //     .then(()=>{
      //       document.fonts.load("16px STSongti-SC")
      //         .then(_this.getReportInfo());
      //     });
      // }catch (e) {
      //   _this.getReportInfo()
      // }

    },
    methods: {
      async getReportInfo() {
        const { token: Authorization, eid } = this.$route.query
        if((this.$route.query.broadcastId&&this.$route.query.broadcastId!='undefined')||this.$route.query.scode) {
          let params,res;
          if(this.$route.query.scode){
            //根据预览链接的scode获取文章详情
            params = {
              scode:this.$route.query.scode
            }
            res = await this.$api.cj.getResourceDetail(params, { Authorization, eid });
          }else {
            params = {
              broadcastId: this.$route.query.broadcastId,
            }
            res = await this.$api.cj.getBroadcastInfo(params, { Authorization, eid })
          }
          if (res.retCode == 200) {
            this.createTime = res.data.createTime;
            this.content = res.data.content.replace(/static.tfcaijing.com/g,'zebra.tfcaijing.com');
            this.columnTitle = res.data.columnTitle;
            this.showReportContent=true;
          }else if(res.retCode==400406){
            //链接已失效
            this.errStatus='resourceErr01';
            this.showErrPage=true;

          }else if(res.retCode==400404){
            this.errStatus='resourceErr02';
            this.showErrPage=true;
          }else{
            this.errStatus='serverErr';
            this.showErrPage=true;
          }
        }else if(sessionStorage.getItem('previewData')) {
          let previewData=JSON.parse(sessionStorage.getItem('previewData'));
          this.createTime = previewData.createTime;
          this.content = previewData.content.replace(/static.tfcaijing.com/g,'zebra.tfcaijing.com');
          this.columnTitle = previewData.columnTitle;
          this.showReportContent=true;
        }
      },
    },
    filters: {
      ConverToDate(date) {
        if(date){
          var dateObj = new Date(date);
          var todaysDate = new Date();
          var isToday=false;//是否为今天
          var month = dateObj.getMonth() + 1;
          var day = dateObj.getDate();
          if (month < 10) {
            month = "0" + month;
          }
          if (day < 10) {
            day = "0" + day;
          }
          if(dateObj.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)){
            isToday=true;
          }
          if(isToday){
            return '今日最新';
          }else{
            var UnixTimeToDate = month + '月' + day + '日 ';
            return UnixTimeToDate;
          }
        }else{
          return "";
        }

      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .report-content {
    padding: 0.98rem 0.88rem 0.6rem;
  }

  .report-title {
    font-family:  "STSongti-SC", sans-serif;
    font-size: 0.84rem;
    color: #333333;
    height: .84rem;
    line-height: .84rem;
  }

  .report-title .time {
    font-size: 0.48rem;
    color: #333333;
    vertical-align: middle;
    height: .84rem;
    line-height: .84rem;

  }

  .report-detail {
    margin-top: 0.54rem;
    position: relative;
    margin-bottom: .7rem;
    font-family:  "STSongti-SC", sans-serif;
  }
</style>
